import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, CircularProgress } from '@material-ui/core';
import { withTranslation } from './config/i18n';

import Routes from './routes';
import { iframeResponse } from './utils';

const useStyles = makeStyles(() => ({
  appLoadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: ' 600px',
    maxWidth: '800px',
    backgroundColor: 'white',
    margin: 'auto',
  },
  circularProgress: {
    color: 'black',
  },
}));

const defaultTheme = () =>
  createMuiTheme({
    palette: {
      type: 'light',
      primary: { main: '#161616' },
      text: {
        primary: '#333',
      },
      backgroundColor: '#fff',
      action: {
        hover: '#b8cbe0',
        selected: 'b8cbe0',
      },
    },
  });

const AppContainer = ({ t }) => {
  const [theme, setTheme] = useState(defaultTheme);
  const discoveryState = useSelector((state) => state.discovery);
  const classes = useStyles();

  const { entity, error } = discoveryState;

  useEffect(() => {
    if (entity?.theme) {
      const entityTheme = () => {
        const {
          font,
          primaryColor,
          secondaryColor,
          primaryTextColor,
          backgroundColor,
          secondaryTextColor,
          errorColor,
        } = entity.theme;

        return createMuiTheme({
          palette: {
            backgroundColor,
            primary: {
              main: primaryColor,
            },
            secondary: {
              main: secondaryColor,
            },
            error: {
              main: errorColor,
            },
            text: {
              primary: primaryTextColor,
              secondary: secondaryTextColor,
            },
          },
          typography: {
            fontFamily: font,
          },
        });
      };

      setTheme(entityTheme);
    }
  }, [entity]);

  useEffect(() => {
    if (error) {
      iframeResponse('ERROR', error);
    }
  }, [error]);

  if (error) {
    return (
      <div className={classes.appLoadingContainer}>{t('reg.noEntityId')}</div>
    );
  }

  if (discoveryState.isFetching) {
    return (
      <div className={classes.appLoadingContainer}>
        <CircularProgress
          className={classes.circularProgress}
          size={60}
          thickness={5}
        />
      </div>
    );
  }

  return (
    <div id="app-routes">
      <MuiThemeProvider theme={theme}>
        <Routes />
      </MuiThemeProvider>
    </div>
  );
};

export default withTranslation(AppContainer);
