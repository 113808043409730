import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { Cryptography } from 'mp-common-js';

import Footer from '../../components/ecomm-integration-footer/EcommFooter';
import Header from '../../components/ecomm-integration-header/EcommHeader';
import TermsAndConditions from '../../components/terms-and-conditions/TermsAndConditions';
import { withTranslation } from '../../config/i18n';
import CreditCardWithEligibilityCheck from '../credit-card-eligibility-validation/CreditCardWithEligibilityCheck';
import OrderConfirmationPage from '../order-confirmation-page/OrderConfirmationPage';
import CreditCardForm from '../credit-card-form/CreditCardForm';
import { iframeResponse } from '../../utils';
import {
  cardDetailsCheckAction,
  paymentCardEligibilityCheckAction,
  setCardValidity,
  updateCard,
  updateBilling,
  updateCardNumber,
} from '../../state/actions/EnrollmentActions';

import styles from './styles';

export class CreditCardRegistration extends Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { iframe, enrollment } = props;

    const { card, billingAddress } =
      enrollment.card && enrollment.billingAddress
        ? enrollment
        : this.buildUserFromParams(iframe);

    // eslint-disable-next-line no-unused-expressions
    iframe.enabled && this.handleUpdate(card, billingAddress);

    this.state = {
      publicKeyExists: false,
      activeStep: enrollment.eligibility?.eligibilityReceipt ? 3 : 0,
      showErrors: false,
      termsAndConditionsChecked: false,
      termsCheckedTimestamp: undefined,
      eligibility: enrollment.eligibility || undefined,
    };
  }

  componentDidMount() {
    this.getPublicKey();
  }

  getPublicKey() {
    const publicKey = Cryptography.RSACrypto.getPublicKey();

    this.setState({ publicKeyExists: Boolean(publicKey) });

    if (!publicKey) {
      iframeResponse('ERROR', 'public key is missing');
    }
  }

  buildUserFromParams = (params) => {
    const { t } = this.props;

    const countries = t('countryCodeList', { returnObjects: true });
    const countriesArray = Object.keys(countries).reduce(
      (acc, e) => [...acc, { code: e, name: countries[e] }],
      [],
    );

    const { name, address, postalCode, country } = params;

    let billingAddress = {
      billingAddress: {
        line1: address ? address.split('/')[0] : '',
        line2: address ? address.split('/')[1] : '',
        postalCode: postalCode || '',
        country: country ? country.toUpperCase() : '',
      },
    };

    if (country) {
      billingAddress = countriesArray.some(
        (e) => e.code === country.toUpperCase(),
      )
        ? billingAddress
        : null;
    }

    return {
      ...billingAddress,
      card: {
        cardholderName: name || '',
      },
    };
  };

  handleStepperBack = () => {
    const { activeStep } = this.state;

    if (activeStep !== 0) {
      this.setState((state) => {
        const newStep = state.activeStep - 1;
        return { activeStep: newStep };
      });
    } else iframeResponse('USER_HAS_CANCELLED');
  };

  handleEligibleCard = () => {
    this.setState((state) => {
      const { enrollment } = this.props;
      const { card } = enrollment;
      return {
        card,
        activeStep: state.activeStep + 1,
      };
    });
  };

  onHideOrderConfirmation = () => {
    this.setState({ activeStep: 1 });
  };

  handlePaymentCard = (eligibility) =>
    this.setState((state) => {
      const { enrollment } = this.props;
      const { card } = enrollment;
      const newStep = state.activeStep + 1;
      return { card, eligibility, activeStep: newStep };
    });

  handleTCCheck = (event) => {
    const { checked } = event.target;
    this.setState({ termsAndConditionsChecked: checked });
  };

  handleUpdate = (card, billingAddress) => {
    const { onUpdateCard, onUpdateBilling } = this.props;

    onUpdateCard(card);
    onUpdateBilling(billingAddress);
  };

  handleFinishRegistration = () => {
    const { history } = this.props;
    const { termsAndConditionsChecked } = this.state;
    if (termsAndConditionsChecked) {
      this.setState({ termsCheckedTimestamp: new Date() });
      history.push('/signup-alternative', {
        state: { from: history.location },
      });
    } else {
      this.setState({ showErrors: true });
    }
  };

  stepContent = (step) => {
    const { termsAndConditionsChecked, eligibility, showErrors } = this.state;
    const {
      security,
      classes,
      enrollment,
      onCheckCardEligibilityClick,
      onCardCheck,
      onUpdateAccountNumber,
      onSetCardValidity,
    } = this.props;
    const {
      isFetching,
      card,
      billingAddress,
      cardValidity,
      cardConfig,
    } = enrollment;
    const { user, deviceInfo } = security;

    if (document.getElementById('root'))
      document.getElementById('root').scrollIntoView();

    switch (step) {
      case 0:
        return (
          <OrderConfirmationPage
            data-test="order-confirmation-page"
            deviceInfo={deviceInfo}
            navigationComponent={Footer}
            navigationComponentProps={{
              onPrev: this.handleStepperBack,
              onNext: this.onHideOrderConfirmation,
            }}
          />
        );
      case 1:
        return (
          <CreditCardWithEligibilityCheck
            data-test="credit-card-with-eligibility"
            navigationComponent={Footer}
            onCheck={onCheckCardEligibilityClick}
            isFetching={isFetching}
            cardValidity={cardValidity}
            handleUpdate={onUpdateAccountNumber}
            value={card.accountNumber}
            setIsCardValid={onSetCardValidity}
            user={user}
            navigationComponentProps={{
              onPrev: this.handleStepperBack,
              onNext: this.handleEligibleCard,
            }}
          />
        );
      case 2:
        return (
          <div className={classes.stepContainer}>
            <CreditCardForm
              data-test="credit-card-form"
              onCardCheck={onCardCheck}
              isFetching={isFetching}
              handleUpdate={this.handleUpdate}
              card={card}
              cardConfig={cardConfig}
              billingAddress={billingAddress}
              navigationComponent={Footer}
              navigationComponentProps={{
                onPrev: this.handleStepperBack,
                onNext: this.handlePaymentCard,
              }}
            />
          </div>
        );
      case 3:
        return (
          <div className={classes.stepContainer}>
            <TermsAndConditions
              data-test="terms-and-conditions"
              eligibility={eligibility}
              checked={termsAndConditionsChecked}
              onChange={this.handleTCCheck}
              showError={showErrors}
            />
            <Footer
              data-test="terms-footer"
              onPrev={this.handleStepperBack}
              onNext={this.handleFinishRegistration}
            />
          </div>
        );

      default:
        return (
          <CreditCardWithEligibilityCheck
            data-test="credit-card-with-eligibility"
            navigationComponent={Footer}
            onCheck={onCheckCardEligibilityClick}
            isFetching={isFetching}
            cardValidity={cardValidity}
            handleUpdate={onUpdateAccountNumber}
            value={card.accountNumber}
            setIsCardValid={onSetCardValidity}
            user={user}
            navigationComponentProps={{
              onPrev: this.handleStepperBack,
              onNext: this.handleEligibleCard,
            }}
          />
        );
    }
  };

  renderPublicKeyError() {
    const { t, classes } = this.props;
    return (
      <div className={classes.publicKeyError} data-test="public-key-error">
        <Grid
          container
          direction="column"
          justify="space-around"
          alignItems="center"
        >
          <Grid item>
            <Typography color="error" variant="h4">
              {t('reg.notAuthorised')}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const { history, classes, t } = this.props;
    const { activeStep, publicKeyExists } = this.state;
    const steps = t('cardRegistration.steps', {
      returnObjects: true,
    });

    const stepperLabels = Object.keys(steps).reduce(
      (acc, e) => [...acc, steps[e]],
      [],
    );

    if (!publicKeyExists) {
      return this.renderPublicKeyError();
    }

    return (
      <div className={classes.mainContainer}>
        <Header pathname={history.location.pathname} />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <div className={classes.stepper}>
            <Stepper data-test="stepper" activeStep={0} alternativeLabel>
              {stepperLabels.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {this.stepContent(activeStep)}
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onUpdateAccountNumber: (accountNumber) =>
    dispatch(updateCardNumber(accountNumber)),
  onUpdateCard: (card) => dispatch(updateCard(card)),
  onUpdateBilling: (billingAddress) => dispatch(updateBilling(billingAddress)),
  onCheckCardEligibilityClick: (accountNumber, onSuccess, onError) => {
    dispatch(
      paymentCardEligibilityCheckAction(accountNumber, onSuccess, onError),
    );
  },
  onCardCheck: (accountNumber, onSuccess, onError) => {
    dispatch(cardDetailsCheckAction(accountNumber, onSuccess, onError));
  },
  onSetCardValidity: (isCardValid) => dispatch(setCardValidity(isCardValid)),
});

const mapStateToProps = (state) => {
  const { enrollment, iframe, security } = state;
  return { enrollment, iframe, security };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation(withStyles(styles)(CreditCardRegistration))),
);
