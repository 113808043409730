import { Services } from 'mp-common-js';

import {
  GET_ENTITY_BY_ID,
  GET_ENTITY_BY_ID_SUCCESS,
  GET_ENTITY_BY_ID_ERROR,
} from './ActionTypes';

export const getEntityByIdEvent = (type, payload) => ({
  type,
  payload,
});

export const getEntityByIdAction = (entityId) => (dispatch) => {
  dispatch(getEntityByIdEvent(GET_ENTITY_BY_ID));
  return Services.DiscoveryService.getEntityById(entityId).then(
    (response) => {
      dispatch(getEntityByIdEvent(GET_ENTITY_BY_ID_SUCCESS, response.data));
    },
    (error) => {
      dispatch(getEntityByIdEvent(GET_ENTITY_BY_ID_ERROR, error.response.data));
    },
  );
};
