const styles = (theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.backgroundColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    minHeight: '600px',
    maxWidth: '800px',
    margin: 'auto',
  },
  typography: {
    padding: '0 20px',
  },
  finalStepsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '350px',
    width: '100%',

    '& > div': {
      width: '280px',
      height: '230px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      margin: '10px 20px',
      boxShadow: `0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2)`,
      borderRadius: '8px',
    },
    '& p': {
      width: '240px',
      padding: '0 20px',
      fontSize: '1.2em',
    },
  },
  icon: {
    fontSize: '10em',
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '140px',
    height: '110px',
  },
  iconSteps: {
    fontSize: '7em',
    color: theme.palette.primary.main,
  },
});

export default styles;
