import {
  GET_ENTITY_BY_ID,
  GET_ENTITY_BY_ID_SUCCESS,
  GET_ENTITY_BY_ID_ERROR,
} from '../actions/ActionTypes';

const defaultDiscoveryState = {
  isFetching: false,
  entity: null,
  error: '',
};

export default (state = defaultDiscoveryState, action) => {
  switch (action.type) {
    case GET_ENTITY_BY_ID:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ENTITY_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        entity: action.payload,
        error: '',
      };
    case GET_ENTITY_BY_ID_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload.message,
      };
    default:
      return state;
  }
};
