import { Services } from 'mp-common-js';

import {
  SIGN_IN,
  SIGN_OUT,
  SET_CLIENT_ID,
  DEVICE_VALIDATION,
  DEVICE_VALIDATION_SUCCESS,
  DEVICE_VALIDATION_ERROR,
  SET_DEVICE_VALID,
  GET_USER,
  SET_USER,
} from './ActionTypes';

import errorEvent from './ErrorActions';
import { getEntityByIdAction } from './DiscoveryActions';
import { iframeResponse } from '../../utils';

export const signInEvent = (status, error) => ({
  type: SIGN_IN,
  status,
  error,
});

export const deviceValidationEvent = (type, payload) => ({
  type,
  payload,
});

export const getUserEvenet = (status, error) => ({
  type: GET_USER,
  status,
  error,
});

export const setDeviceValid = (isValid) => ({
  type: SET_DEVICE_VALID,
  isValid,
});

export const signOutEvent = () => ({
  type: SIGN_OUT,
});

export const setClientId = (clientId) => ({
  type: SET_CLIENT_ID,
  clientId,
});

export const setUserEvent = (user, alreadyExists) => ({
  type: SET_USER,
  user,
  alreadyExists,
});

export const validateDeviceAction = (device) => {
  const { brand, model, version, variant } = device;
  const { color } = variant;

  return (dispatch) => {
    dispatch(deviceValidationEvent(DEVICE_VALIDATION));
    return Services.DiscoveryService.getDeviceByBrand(
      brand,
      model,
      version,
      color,
    ).then(
      (response) => {
        dispatch(
          deviceValidationEvent(DEVICE_VALIDATION_SUCCESS, response.data),
        );
        if (response.data?.entityId) {
          dispatch(getEntityByIdAction(response.data.entityId));
        }
      },
      (error) => {
        dispatch(
          deviceValidationEvent(DEVICE_VALIDATION_ERROR, error.response.data),
        );
        iframeResponse('ERROR', error.response.data.message);
      },
    );
  };
};

export const signInAction = (username, password, clientId) => (dispatch) => {
  dispatch(signInEvent('processing'));

  return Services.SecurityService.signIn({ username, password }, clientId).then(
    (resp) => {
      const { data } = resp;

      localStorage.setItem('token', JSON.stringify(data));
      dispatch(signInEvent('success'));
    },
    (error) => {
      dispatch(signInEvent('error'));
      iframeResponse('ERROR', error.response.data.message);
      if (error.response.data.message === 'User is disabled')
        dispatch(errorEvent('inactive'));
      else dispatch(errorEvent(error.response.status));
    },
  );
};

export const getUserAction = (username, onSuccess, onError) => (dispatch) => {
  dispatch(getUserEvenet('processing'));
  return Services.SecurityService.getUserAccount(username).then(
    () => {
      onSuccess();
      dispatch(getUserEvenet('success'));
    },
    () => {
      onError();
      dispatch(getUserEvenet('error'));
    },
  );
};
