import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from '../../config/i18n';

import pciLogo from './img/pciLogo.png';
import mastercardLogo from './img/mc_symbol.svg';
import maestroLogo from './img/maestroLogo.svg';
import bancontactLogo from './img/bancontact_logo.png';
import paymentReadyLogo from './img/payment-ready.svg';

import styles from './styles';

const IframeFooter = ({ discovery, classes, t, showCreditsImages }) => (
  <div className={classes.footerCreditsMainContainer}>
    {showCreditsImages && (
      <div className={classes.footerCreditsImages}>
        <img src={paymentReadyLogo} alt="payment ready logo" />
        <img src={mastercardLogo} alt="mastercard logo" />
        <img src={maestroLogo} alt="maestro logo" />
        <img src={bancontactLogo} alt="bancontact logo" />
        <img src={pciLogo} alt="pci logo" />
      </div>
    )}
    {discovery.entity?.name && (
      <Typography
        variant="caption"
        gutterBottom
        className={classes.footerCreditsText}
      >
        {t('loadingPage.footerText', {
          oemName: discovery.entity.name,
        })}
      </Typography>
    )}
  </div>
);

IframeFooter.propTypes = {
  discovery: PropTypes.shape({
    state: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  discovery: state.discovery,
});

export default connect(mapStateToProps)(
  withTheme(withTranslation(withStyles(styles)(IframeFooter))),
);
