import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withTheme, withStyles } from '@material-ui/core/styles';

import { withTranslation } from '../../config/i18n';

import styles from './styles';

export const TermsAndConditions = ({
  showError,
  onChange,
  eligibility,
  checked,
  classes,
  t,
}) => {
  if (!eligibility?.tacAssetUrl) {
    return (
      <FormHelperText data-test="get-terms-error" error>
        {t('components.termsAndConditions.error')}
      </FormHelperText>
    );
  }

  function ClickableLink() {
    return (
      <span
        className={classes.tcLink}
        onClick={() => window.open(eligibility.tacAssetUrl, '_blank')}
        onKeyPress={() => window.open(eligibility.tacAssetUrl, '_blank')}
        role="link"
        tabIndex={0}
      >
        {t('components.termsAndConditions.link')}
      </span>
    );
  }
  return (
    <div data-test="terms-container" className={classes.termsMainContainer}>
      <div className={classes.checkBoxTextContainer}>
        <FormControlLabel
          data-test="terms-label"
          control={
            <Checkbox
              checked={checked}
              data-test="check-box"
              onChange={onChange}
              color="primary"
            />
          }
          label={
            <Typography>
              <Trans i18nKey="components.termsAndConditions.label">
                <ClickableLink data-test="link" />
              </Trans>
            </Typography>
          }
        />
      </div>
      <div>
        {!checked && showError && (
          <FormHelperText data-test="not-selected-error" error>
            {t('components.termsAndConditions.notSelectedError')}
          </FormHelperText>
        )}
      </div>
    </div>
  );
};

TermsAndConditions.propTypes = {
  onChange: PropTypes.func.isRequired,
  eligibility: PropTypes.shape({
    tacAssetUrl: PropTypes.string,
  }).isRequired,
  checked: PropTypes.bool.isRequired,
  showError: PropTypes.bool,
  style: PropTypes.shape({}),
};

TermsAndConditions.defaultProps = {
  showError: null,
  style: null,
};

export default withTheme(
  withTranslation(withStyles(styles)(TermsAndConditions)),
);
