import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withTheme, withStyles } from '@material-ui/core';

import { withTranslation } from '../../config/i18n';

import styles from './styles';

const CountriesSelect = ({ t, theme, ...props }) => {
  const countries = t('countryCodeList', { returnObjects: true });
  const countriesArray = Object.keys(countries).reduce(
    (acc, e) => [...acc, { code: e, name: countries[e] }],
    [],
  );

  const featured = ['BEL', 'NLD', 'GBR'];

  const { onChange, value, classes } = props;

  return (
    <FormControl fullWidth>
      <InputLabel required htmlFor="country">
        {t('creditCardForm.countryLabel')}
      </InputLabel>
      <Select
        {...{ onChange }}
        value={value}
        name="country"
        input={<Input id="expMonth" />}
        fullWidth
      >
        {countriesArray
          .filter((e) => featured.indexOf(e.code) > -1)
          .concat(countriesArray.sort((a, b) => a.name.localeCompare(b.name)))
          .map((c, i) => (
            <MenuItem
              classes={{ root: classes.root }}
              className={
                i === featured.length - 1 ? classes.menuItemSeparator : null
              }
              value={c.code}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              selected={value === c.code}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = theme.palette.action.hover;
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = theme.palette.backgroundColor;
              }}
            >
              {c.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

CountriesSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default withTranslation(withTheme(withStyles(styles)(CountriesSelect)));
