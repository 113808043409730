import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { extractParameters } from './utils';
import { history } from './state/store';

import Iframe from './iframe/Iframe';
import HomePage from './iframe/webpage-home/HomePage';
import LoadingPage from './iframe/loading-page/LoadingPage';
import SignInPage from './containers/sign-in/SignIn';
import SignUpPage from './containers/sign-up/SignUp';
import SignUpAlternative from './containers/sign-up-alternative/SignUpAlternative';
import CreditCardRegistrationPage from './containers/credit-card-registration/CreditCardRegistration';
import EnrollmentConfirmationPage from './containers/enrollment-confirmation/EnrollmentConfirmation';

const Routes = () => (
  <div id="routesContainer">
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <IframeRoute path="/ecommerce" />
        <Route path="/signin">
          <SignInPage />
        </Route>
        <Route path="/signup">
          <SignUpPage />
        </Route>
        <Route path="/signup-alternative">
          <SignUpAlternative history={history} />
        </Route>
        <Route path="/enrolled">
          <EnrollmentConfirmationPage history={history} />
        </Route>
        <Route path="/credit-card-registration">
          <CreditCardRegistrationPage />
        </Route>
      </Switch>
    </ConnectedRouter>
  </div>
);

/* const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    (!localStorage.getItem("token")) ? (<Redirect to={{ pathname: '/signin', state: { from: props.location } }} />) : (<Component {...props} />)
  )} />
)
*/

const IframeRoute = ({ component: Component, ...rest }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setVisible(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        const params = extractParameters(props.location.search);
        return (
          <Iframe
            component={visible ? LoadingPage : CreditCardRegistrationPage}
            params={params}
            {...props}
          />
        );
      }}
    />
  );
};

export default Routes;
