import {
  SET_CLIENT_ID,
  DEVICE_VALIDATION,
  SET_USER,
  GET_USER,
  DEVICE_VALIDATION_SUCCESS,
  DEVICE_VALIDATION_ERROR,
} from '../actions/ActionTypes';

const defaultSecurityState = {
  isLoggedIn: localStorage.getItem('token') !== null,
  user: undefined,
  alreadyExists: undefined,
  isProcessing: false,
  redirectToReferrer: false,
  isDeviceValid: null,
  deviceInfo: undefined,
  errorValidatingDevice: null,
};

export default (state = defaultSecurityState, action) => {
  const isProcessing = action.status === 'processing';
  switch (action.type) {
    case SET_CLIENT_ID:
      return {
        ...state,
        clientId: action.clientId,
      };
    case DEVICE_VALIDATION:
      return {
        ...state,
        isProcessing: true,
        error: false,
      };
    case DEVICE_VALIDATION_SUCCESS:
      return {
        ...state,
        isDeviceValid: true,
        isProcessing: false,
        error: false,
        deviceInfo: action.payload,
      };
    case DEVICE_VALIDATION_ERROR:
      return {
        ...state,
        isProcessing: false,
        errorValidatingDevice: action.payload.message,
      };
    case GET_USER:
      return {
        ...state,
        isProcessing,
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
        alreadyExists: action.alreadyExists,
      };
    default:
      return state;
  }
};
