import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import digiseqLogo from './images/manageMiiLogo.svg';

const Logo = ({ height, width }) => {
  const discoveryState = useSelector((state) => state.discovery);
  const [logo, setLogo] = useState(digiseqLogo);
  const { entity } = discoveryState;

  useEffect(() => {
    if (entity?.logo) {
      setLogo(entity.logo);
    }
  }, [entity]);

  const logoContainerStyle = {
    height,
    width,
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${logo})`,
  };

  return <div style={logoContainerStyle} />;
};

Logo.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default Logo;
