const styles = (theme) => ({
  termsMainContainer: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  checkBoxTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 15px',
  },
  tcLink: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default styles;
