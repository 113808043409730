import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import security from './SecurityReducers';
import enrollment from './EnrolmentReducers';
import signUp from './SignUpReducers';
import error from './ErrorReducers';
import iframe from './IframeReducers';
import discovery from './DiscoveryReducers';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    security,
    signUp,
    enrollment,
    error,
    iframe,
    discovery,
  });

export default createRootReducer;
