// Security
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_UP = 'SIGN_UP';
export const SET_CLIENT_ID = 'SET_CLIENT_ID';
export const DEVICE_VALIDATION = 'DEVICE_VALIDATION';
export const DEVICE_VALIDATION_SUCCESS = 'DEVICE_VALIDATION_SUCCESS';
export const DEVICE_VALIDATION_ERROR = 'DEVICE_VALIDATION_ERROR';
export const SET_DEVICE_VALID = 'SET_DEVICE_VALID';
export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';

// Enrollment
export const CARD_ELIGIBILITY_CHECK = 'CARD_ELIGIBILITY_CHECK';
export const CARD_DETAILS_CHECK = 'CARD_DETAILS_CHECK';
export const UPDATE_CARD_NUMBER = 'UPDATE_CARD_NUMBER';
export const UPDATE_CARD = 'UPDATE_CARD';
export const UPDATE_BILLING = 'UPDATE_BILLING';
export const RESET_CARD = 'RESET_CARD';
export const ENROLL = 'ENROLL';
export const SET_DEVICE = 'SET_DEVICE';
export const ERROR = 'ERROR';
export const UPDATE_CARD_VALIDITY = 'UPDATE_CARD_VALIDITY';
export const SET_CARD_CONFIG = 'SET_CARD_CONFIG';

// iframe
export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS';

// discovery

export const GET_ENTITY_BY_ID = 'GET_ENTITY_BY_ID';
export const GET_ENTITY_BY_ID_SUCCESS = 'GET_ENTITY_BY_ID_SUCCESS';
export const GET_ENTITY_BY_ID_ERROR = 'GET_ENTITY_BY_ID_ERROR';
