import React from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ErrorIcon from '@material-ui/icons/HighlightOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContactSupport, MailOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Configuration from 'mp-common-js/dist/config/Configuration';
import { withTranslation } from '../../config/i18n';

import StyledButton from '../../components/styled-button/StyledButon';
import IframeFooter from '../../components/iframe-footer/IframeFooter';

import {
  resetCard,
  onEnrollAction,
} from '../../state/actions/EnrollmentActions';
import { signUpAction } from '../../state/actions/SignUpActions';

import { iframeResponse } from '../../utils';

import styles from './styles';

export class EnrollmentConfirmation extends React.Component {
  static propTypes = {
    onEnroll: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    enrollment: PropTypes.shape({
      eligibility: PropTypes.object,
      card: PropTypes.object,
      billingAddress: PropTypes.object,
    }),
    onResetCard: PropTypes.func.isRequired,
    history: PropTypes.shape({}).isRequired,
    classes: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    enrollment: {
      card: {},
      billingAddress: {},
      eligibility: undefined,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      processing: true,
      enrollmentError: '',
      imageError: false,
      productInfo: {},
    };
  }

  componentDidMount() {
    const { onEnroll, enrollment, security, onSignUp, i18n, t } = this.props;
    const { eligibility, card, billingAddress, cardConfig } = enrollment;
    const { user, alreadyExists, deviceInfo } = security;
    const { addressRequired, emailSender, wallet, issuerName } = cardConfig;

    if (cardConfig.cvcRequired === false) {
      card.securityCode = null;
    }

    onEnroll(
      {
        ...card,
        ...(addressRequired !== false
          ? { billingAddress: { ...billingAddress } }
          : null),
      },
      eligibility,
      (response) => {
        const environmentFormat = {
          development: 'dev-',
          staging: 'staging-',
          demo: 'demo-',
          production: '',
        };

        if (!alreadyExists) {
          const signUpOptions = {
            sendConfirmationEmail:
              emailSender && emailSender.toUpperCase() === 'MANAGE-MII',
            iosAppUrl:
              wallet?.iosAppUrl ||
              'https://apps.apple.com/gb/app/manage-mii/id1436896422',
            androidAppUrl:
              wallet?.androidAppUrl ||
              'https://play.google.com/store/apps/details?id=com.managemii',
            issuer: response?.productInfo?.issuerName,
            issuerImage: response?.productInfo?.issuerLogo,
            oem: deviceInfo?.brandDisplayName,
            oemImage:
              wallet?.oemImage ||
              `https://${
                environmentFormat[Configuration.getConfig().environment]
              }managemii-discover.s3.eu-west-2.amazonaws.com/${
                deviceInfo?.brand
              }/${deviceInfo?.brand}_logo.png`,
            mobileApp: wallet?.mobileApp || 'Manage-Mii',
            language: i18n?.language
          };
          onSignUp(user, signUpOptions);
        }

        const { productInfo } = response;

        if (productInfo) {
          this.setState({ productInfo });
        }
        // Post the asn to the iframe parent to inform about the device serial number
        iframeResponse('ENROLLMENT_SUCCESS', enrollment.eligibility.asn);
        iframeResponse('ENROLLMENT_SUCCESS_2', {
          asn: enrollment.eligibility.asn,
          wallet,
          userEmail: user,
          language: productInfo?.language,
          brand: {
            name: productInfo?.issuerName,
            logoUrl: productInfo?.issuerLogo,
          },
          issuerTechnicalName: issuerName,
          shortAsn: response.shortAsn,
          qrCode: productInfo?.qrCode,
          documentation: productInfo?.documentation,
          customerSupport: {
            email: productInfo?.customerServiceEmail,
            phone: productInfo?.customerServicePhoneNumber,
            web: productInfo?.customerServiceUrl,
          },
        });

        this.setState({ processing: false });
      },
      (errorMessage) => {
        const message =
          errorMessage === undefined
            ? t('generalError.somethingWentWrong')
            : errorMessage;
        iframeResponse('ENROLLMENT_FAILED', message);
        this.setState({ processing: false, enrollmentError: message });
      },
    );
  }

  handleOnTryAgain = () => {
    const { onResetCard, history } = this.props;
    onResetCard();
    history.goBack();
  };

  isCardDeclinedMsg() {
    const { t } = this.props;
    return (
      <Grid item>
        <Typography variant="subtitle1">
          {t('enrollment.failure.subheading1')}
        </Typography>
        <Typography variant="subtitle1">
          {t('enrollment.failure.subheading2')}
        </Typography>
      </Grid>
    );
  }

  renderFailedResult() {
    const { enrollmentError } = this.state;
    const { t, classes } = this.props;

    return (
      <>
        <div data-test="failed-result" className={classes.typography}>
          <Typography variant="h3">{t('enrollment.failure.title')}</Typography>
          <Typography variant="subtitle1">{enrollmentError}</Typography>
        </div>
        <ErrorIcon color="error" className={classes.icon} />
        <div className={classes.typography}>
          {enrollmentError.includes('2003') && this.isCardDeclinedMsg()}
          <Typography variant="subtitle1">
            {t('enrollment.failure.subheading3')}
          </Typography>
        </div>
        <StyledButton onClick={this.handleOnTryAgain}>
          {t('button.tryAgain')}
        </StyledButton>
      </>
    );
  }

  renderSuccessResult() {
    const { t, classes, iframe, enrollment, security } = this.props;
    const { productInfo, imageError } = this.state;
    const { cardConfig } = enrollment;
    const { emailSender } = cardConfig;
    const { skipLastPage } = iframe;
    const { deviceInfo } = security;

    const bankName =
      productInfo?.issuerName || t('enrollment.success.yourBank');

    let emailSenderName;
    if (emailSender && emailSender.toUpperCase() === 'ISSUER')
      emailSenderName = bankName;
    else if (emailSender && emailSender.toUpperCase() === 'OEM')
      emailSenderName = deviceInfo.brand;
    else emailSenderName = emailSender || bankName;

    if (!skipLastPage) {
      return (
        <>
          <div data-test="success-result" className={classes.typography}>
            <Typography variant="h3" color="primary">
              {t('enrollment.success.title')}
            </Typography>
            <Typography variant="subtitle1">
              {t('enrollment.success.heading')}
            </Typography>
          </div>
          <div className={classes.finalStepsContainer}>
            {emailSender && emailSender !== 'none' && (
              <div className={classes.step}>
                <div className={classes.imgContainer}>
                  <MailOutline className={classes.iconSteps} />
                </div>
                <Typography variant="body1" gutterBottom>
                  {t('enrollment.success.subheading1', {
                    emailSenderName,
                  })}
                </Typography>
              </div>
            )}
            <div className={classes.step}>
              <div className={classes.imgContainer}>
                {productInfo?.issuerLogo && !imageError ? (
                  <img
                    src={productInfo.issuerLogo}
                    onError={() => this.setState({ imageError: true })}
                    alt="issuer logo"
                    width="100%"
                  />
                ) : (
                  <ContactSupport className={classes.iconSteps} />
                )}
              </div>
              <Typography variant="body1" gutterBottom>
                {t('enrollment.success.subheading2', {
                  bankName,
                })}
              </Typography>
            </div>
          </div>
          <IframeFooter />
        </>
      );
    }

    return null;
  }

  renderResult() {
    const { enrollmentError } = this.state;
    return enrollmentError === ''
      ? this.renderSuccessResult()
      : this.renderFailedResult();
  }

  render() {
    const { processing } = this.state;
    const { classes, signUp } = this.props;
    const { isProcessing } = signUp;

    return (
      <div data-test="processing" className={classes.mainContainer}>
        {processing || isProcessing ? (
          <CircularProgress data-test="circular-progress" size={60} />
        ) : (
          this.renderResult()
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onEnroll: (card, eligibility, onSuccess, onError) => {
    dispatch(onEnrollAction(card, eligibility, onSuccess, onError));
  },
  onResetCard: () => {
    dispatch(resetCard());
  },
  onSignUp: (username, options) =>
    dispatch(signUpAction({ username }, options)),
});

const mapStateToProps = (state) => ({
  enrollment: state.enrollment,
  security: state.security,
  iframe: state.iframe,
  signUp: state.signUp,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(withStyles(styles)(EnrollmentConfirmation)));
