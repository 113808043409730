const styles = (theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.backgroundColor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '580px',
    maxWidth: '800px',
    margin: 'auto',
  },
  publicKeyError: {
    margin: 'auto',
    textAlign: 'center',
    width: '90%',
  },
  stepper: {
    // IE11 FIX
    width: '75%',
    '@media (max-width: 425px)': {
      width: '100%',
    },
  },
});

export default styles;
